.StudyPortfolioSection {
    @mixin studyPageSectionPadding;
    @mixin fullPageGrid;
    color: var(--whiteBaseColor);
    background-color: var(--blackBaseColor);
}

.Information {
    margin-bottom: 8px;

    @media (--tablet) {
        margin-bottom: 80px;
    }
}

.Title {
    @mixin sectionHeading;
    margin-bottom: 24px;
}

.TeaserWrapper {
    @media (--tablet) {
        @mixin grid;
        grid-template-columns: repeat(12, 1fr);
    }
}

.Teaser {
    @mixin hyphenateWords manual;
    @mixin sectionTeaser;

    @media (--tablet) {
        grid-column: 3 / span 8;
    }
}
