.PortfolioItemInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
}

.Title {
    @mixin p30;
    @mixin hyphenateWords;
    font-weight: 600;

    @media (--tablet) {
        @mixin p10;
    }
}

.Variant--visibleOnInteractOnly {
    & > * {
        @mixin animatePropertyFaster opacity;
        opacity: 0;
        align-self: start;
    }

    & .Link {
        @mixin onFocus {
            @mixin focusVisible 5px;
            opacity: 1;

            &::before {
                background: rgb(var(--blackBaseRgb) / 30%);
            }

            & + * {
                opacity: 1;
            }
        }

        &::before {
            @mixin absoluteParentSize;
            @mixin imageHoverAnimation background;
            content: '';
            z-index: -1;
        }
    }
}

.VisibleOnInteractOnlyContainer:hover {
    & .Variant--visibleOnInteractOnly {
        & > * {
            opacity: 1;
        }

        & .Link {
            &::before {
                background: transparent;
            }
        }
    }
}

.Caption {
    @mixin hyphenateWords;
    @mixin p30;
}

.IconWrapper {
    display: flex;
    justify-content: space-between;
    gap: 8px;
}

.Icon {
    @mixin fixedRatioSvgIcon 24px;
    align-self: end;

    @media (--tablet) {
        @mixin fixedRatioSvgIcon 32px;
    }

    &:only-child {
        margin-left: auto;
    }
}

.Orientation--vertical {
    & .Title {
        @mixin lineClamp 2;
    }

    & .Caption {
        @mixin lineClamp 3;
    }
}

.Orientation--horizontal {
    & .Title {
        @mixin lineClamp 1;
    }

    & .Caption {
        @mixin lineClamp 2;
    }
}
