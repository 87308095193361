.Items {
    @mixin clearList;
    display: flex;
    gap: 16px;
    margin: 0 calc(-1 * var(--wrapperHorizontalPadding));
    padding: 0 var(--wrapperHorizontalPadding);

    @media (--mobileOnly) {
        overflow: scroll hidden;
    }

    @media (--tablet) {
        @mixin grid;
        grid-template-columns: repeat(12, 1fr);
        margin: 0;
        padding: 0;
    }
}

.Item {
    flex: 1 0 100%;

    @media (--mobileOnly) {
        align-self: end;
    }

    @media (--tablet) {
        &:nth-child(1) {
            grid-column: 1 / span 6;
            grid-row: 1;
            align-self: end;

            @media (--desktopHD) {
                grid-column: 1 / span 5;
            }
        }

        &:nth-child(2) {
            grid-column: 7 / span 6;
            grid-row: 1;

            @media (--desktopHD) {
                grid-column: 6 / span 4;
            }
        }

        &:nth-child(3) {
            grid-column: 2 / span 5;
            grid-row: 2;

            @media (--desktopHD) {
                grid-column: 10 / span 3;
                grid-row: 1;
                align-self: end;
            }
        }

        &:nth-child(4) {
            grid-column: 7 / span 4;
            grid-row: 2 / span 2;

            @media (--desktopHD) {
                grid-column: 2 / span 2;
                grid-row: 2;
                align-self: start;
            }
        }

        &:nth-child(5) {
            grid-column: 3 / span 4;
            grid-row: 3 / span 3;

            @media (--desktopHD) {
                grid-column: 4 / span 3;
                grid-row: 2 / span 2;
                align-self: start;
            }
        }

        &:nth-child(6) {
            grid-column: 7 / span 6;
            grid-row: 4;

            @media (--desktopHD) {
                grid-column: 7 / span 6;
                grid-row: 2;
            }
        }

        &:nth-child(7) {
            grid-column: 7 / span 5;
            grid-row: 5;

            @media (--desktopHD) {
                grid-column: 7 / span 4;
                grid-row: 3;
            }
        }
    }
}
