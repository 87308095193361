.PortfolioItem {
    color: var(--whiteBaseColor);
    position: relative;

    & .Info:only-child {
        margin-top: auto;
    }
}

.Content {
    @mixin absoluteParentSize;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
    z-index: 2;
    padding: 24px;
}

.Overlay {
    @mixin absoluteParentSize;
    z-index: 1;

    &:not(.Overlay--initialTransparent) {
        background: rgb(var(--blackBaseRgb) / 50%);
    }
}

.TypeIcon {
    @mixin fixedRatioSvgIcon 24px;

    @media (--tablet) {
        @mixin fixedRatioSvgIcon 32px;
    }
}

.Variant--rounded {
    @mixin themeRoundedCornersMedium;

    & > * {
        @mixin themeRoundedCornersMedium;
    }
}

.Variant--withLink {
    cursor: pointer;
}

.Variant--withOverlayAnimation {
    &:hover {
        & .Overlay {
            background: rgb(var(--blackBaseRgb) / 30%);
        }
    }

    & .Overlay {
        @mixin imageHoverAnimation background;
    }
}

.Variant--1x1 {
    @mixin aspectRatio 1/1;
}

.Variant--2x3 {
    @mixin aspectRatio 3/2;
}

.Variant--3x2 {
    @mixin aspectRatio 2/3;
}

.Variant--3x4 {
    @mixin aspectRatio 4/3;
}

.Variant--4x3 {
    @mixin aspectRatio 3/4;
}

.Variant--9x16 {
    @mixin aspectRatio 16/9;
}

.Variant--16x9 {
    @mixin aspectRatio 9/16;
}

.Variant--red {
    background-color: var(--primaryHKBaseColor);
}

.Variant--yellow {
    background-color: var(--support01HKDarken40Color);
}

.Variant--teal {
    background-color: var(--support02HKDarken60Color);
}

.Variant--steelblue {
    background-color: var(--support03HKBaseColor);
}

.Variant--white {
    color: var(--blackBaseColor);
    background-color: var(--whiteBaseColor);
}

.Variant--burgundy {
    background-color: var(--support04FKDarken60Color);
}
